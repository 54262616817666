@import '../../../1-variables/app';
@import '../../../2-mixins/theme';

.pm_setting_grid {
  .provider_grid {
    margin-bottom: 0;

    tbody {
      tr {
        td {
          border-bottom: 0;

          &:nth-of-type(1) {
            padding-left: 0;
          }

          &:nth-last-of-type(1) {
            padding-right: 0;
          }
        }

        &:nth-last-of-type(1) {
          td {
            padding-bottom: 0;
          }
        }
      }
    }
  }
  .cost_spec_grid {
    margin-bottom: 0;

    thead {
      th {
        border-bottom-width: 2px;
        @include theme('border-color', $tbl-th-border-gray, !important);
      }
    }
    tbody {
      tr {
        td {
          @include theme('border-color', $line-gray, !important);

          &.spec_name {
            width: 350px;
          }
          &.spec_fee {
            width: 115px;

            div {
              /* width: 100px;
              text-align: right; */
            }
          }
          &.spec_letter {
            width: 115px;
            div {
              // text-align: right;
            }
          }
        }
      }
    }
    tfoot {
      td {
        border: 0;
        .spec_desc {
        }
      }
    }
  }
  .accordion {
    .accordion_body {
      tbody {
        @include theme('background-color', $module-section-bg, !important);
      }
    }
  }
}
