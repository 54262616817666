@function is-important($important) {
  @return #{if($important, '!important', '')};
}

@mixin theme($property, $values, $important: false) {
  @each $theme, $value in $values {
    @if $theme == 'default' {
      #{$property}: $value is-important($important);
    } @else {
      body[data-theme='#{$theme}'] & {
        #{$property}: #{$value} is-important($important);
      }
    }
  }
}
