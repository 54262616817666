@import '../../../1-variables/app';
@import '../../../2-mixins/theme';

.cost_spec_grid {
  margin-bottom: 0;

  thead {
    th {
      border-bottom-width: 2px;
      @include theme('border-color', $tbl-th-border-gray, !important);
    }
  }
  tbody {
    tr {
      td {
        @include theme('border-color', $line-gray, !important);

        &.spec_name {
          width: 400px;
        }
        &.spec_fee {
          width: 115px;
          div {
            padding-right: 1.7em;
            text-align: right;
          }
        }
        &.spec_letter {
          div {
            width: 130px;
            text-align: right;
          }
        }
      }
    }
  }
  tfoot {
    td {
      border: 0;
      .spec_desc {
      }
    }
  }
}
