.profile_view {
  .company_col {
    width: 250px;
  }
  .org_col {
    width: 120px;
    max-width: 120px !important;
  }
  .work_email_col {
    width: 240px;
  }
  .work_phone_col {
    width: 165px;
  }
}
