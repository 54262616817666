.sp_cond_group {
  .sp_cond_parts {
    display: flex;
    &__type {
      margin-right: 1rem;

      .include_part {
        margin-right: 0 !important;
      }
    }
  }
  .sp_other_opt {
    margin-top: 1em;

    .child_leave_opt {
      select {
        width: 77px !important;
      }
      input {
        width: 70px !important;
      }
    }
  }
}
