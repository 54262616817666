.avatar_col {
  display: flex;
  align-items: center;
  > span {
    font-size: 14px;
  }
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 36px;
  height: 36px;
  margin-right: 0.5em;
  line-height: 2.8em !important;
  border-radius: 50%;
  background: #6396d1;
  color: #fff;
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;

  &__placeholder {
    border-bottom: 1px solid #fff;
  }
}
