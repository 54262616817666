@import '../../../../assets/scss/1-variables/app';

$primaryColor: map-get($button-primary-color, 'default');

.workflow_content {
  &__note {
    width: 260px;
    padding-left: 10px;
    border-left: 1px solid $primaryColor !important;
  }
}
