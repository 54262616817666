@import '../../../1-variables/app';
@import '../../../2-mixins/theme';

.pm_setting_grid {
  .insurance_grid {
    margin-bottom: 0;

    .insurance_plan {
      > thead {
        tr {
          .sort_icon_th {
            width: 25px;
          }
          .name_th {
            width: 180px;
          }
          .desc_th {
            width: 311px;
          }
          .age_th {
            width: 233px;
          }
          .sp_condition_th {
            span {
              min-width: 242px;
              width: 242px;
              position: relative;
              left: -50px;
            }
          }
        }
      }
      > tbody {
        > tr {
          td {
            border-bottom: 0;

            &.desc_col_td {
              padding-left: 0 !important;

              table {
                tbody {
                  tr {
                    td {
                      &.desc_cell {
                        width: 311px;
                        padding-top: 0;
                        vertical-align: top;

                        .age_condition {
                          display: flex;
                          margin-top: 0.5em;

                          .age_control {
                          }
                          .condition_control {
                            width: 145px;
                          }
                        }
                      }
                      &.condition_cell {
                        padding-top: 0;
                        vertical-align: top;
                        table {
                          thead {
                            td {
                              vertical-align: top;

                              &.other_action_cell {
                                width: 50px;
                                text-align: right;
                              }
                            }
                          }
                          tbody {
                            tr {
                              td {
                                padding-top: 0.4em;
                                vertical-align: top;

                                &:nth-of-type(1) {
                                  padding-left: 0;
                                }

                                &.other_doc_cell {
                                  .doc_form_group {
                                    display: flex;
                                    width: 100%;

                                    .doc_form_control {
                                      display: flex;
                                      width: 100%;
                                      margin-right: 0.5em;

                                      .doc_form_control_text {
                                        margin-right: 0.5em;
                                        flex-basis: 45%;
                                      }
                                      .doc_form_control_link {
                                        flex-basis: 55%;
                                      }
                                    }
                                    .doc_form_control_action {
                                      width: 42px;
                                    }
                                  }
                                }
                                &.other_action_cell {
                                  width: 50px;
                                  text-align: right;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .cost_spec_grid {
    margin-bottom: 0;

    thead {
      th {
        border-bottom-width: 2px;
        @include theme('border-color', $tbl-th-border-gray, !important);
      }
    }
    tbody {
      tr {
        td {
          @include theme('border-color', $line-gray, !important);

          &.spec_name {
            width: 350px;
          }
          &.spec_fee {
            width: 115px;

            div {
              /* width: 100px;
              text-align: right; */
            }
          }
          &.spec_letter {
            width: 115px;

            div {
              // text-align: right;
            }
          }
        }
      }
    }
    tfoot {
      td {
        border: 0;
        .spec_desc {
        }
      }
    }
  }
  .accordion {
    .accordion_body {
      tbody {
        @include theme('background-color', $module-section-bg, !important);
      }
    }
  }
}
